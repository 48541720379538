$(function() {
  $('#browse-categories').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#browse-categories-menu').slideToggle();
  })

  $('#more-categories').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#more-categories-menu').slideToggle();
  })

  $('#more-submit').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#more-submit-menu').slideToggle();
  })

  $('#main-menu-anchor').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#main-menu').slideToggle();
  })

  $('#main-menu-anchor-fixed').click(function(event) {
    event.preventDefault();
    // event.stopPropagation();
    $('#main-menu-fixed').slideToggle();
  })

  $('#browse-categories-fixed').click(function(event) {
    event.preventDefault();
    // event.stopPropagation();
    $('#browse-categories-menu-fixed').slideToggle();
  })

  $('html').click(function() {
    if($("#browse-categories-menu").css('display') !== 'none') {
      $('#browse-categories-menu').slideToggle();
    }

    if($("#more-categories-menu").css('display') !== 'none') {
      $('#more-categories-menu').slideToggle();
    }

    if($("#more-submit-menu").css('display') !== 'none') {
      $('#more-submit-menu').slideToggle();
    }

    if($("#main-menu").css('display') !== 'none') {
      $('#main-menu').slideToggle();
    }
  });

  if (top.location.pathname !== '/timeline' || top.location.pathname === '/timeline.html') {
    $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 90) {
        $('.fixed-menu').fadeIn();
      }
      else {
        $('.fixed-menu').hide();
      }
    });  
  }
  // Submit Form Page
  var dropdown = $('#form-dropdown')
  var target = $('#form-dropdown').data('target');
  var show = $("option:selected", dropdown).data('show');
  var $contactForm = $('.contact-form');
  
  $(target).children().addClass('hide');
  $(show).removeClass('hide');

  $('#form-dropdown').on('change', function() {
    var target = $(this).data('target');
    var show = $("option:selected", this).data('show');
    $(target).children().addClass('hide');
    $(show).removeClass('hide');
    $contactForm.show();
  });

 $('.submit').on('submit', function(e){
    e.preventDefault();
    var input = $('#csrf').children('input');
    this.appendChild(input[0])
    var form = $(this)
    var serForm = new FormData($(this)[0])
    $.ajax({
      type : 'POST', // HTTP method, always use POST for our form
      url : '/guestEntries/saveEntry',
      data : serForm,
      async : false,
      contentType : false,
      processData: false, // serialized form data
      success: function(data){
        form[0].reset()
        $('.button--contact-form').addClass('hide');
        $('#success-message').removeClass('hide');
        $contactForm.hide();
      },
      error: function(response) {
        
      }
    })
  });
})
