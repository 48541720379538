$(function() {
// Add 'spaces' with blue to create dotted line
  function addDashesToSpiral() {
    var strokeWidthVal = 1;
    $("#spiral line").each(function (i, object) {
      $(object).css('stroke-width', strokeWidthVal+"px");
      if(i % 325 === 0) {
        strokeWidthVal += 1;
      }
      if(i % 3 === 0) {
        $(object).addClass('data-slide-blue');
      }
    })
  }
// Add 'spaces' with blue to create dotted line
  function addDashesToSpiralMobile() {
    var strokeWidthVal = 1;
    $("#spiral-mobile line").each(function (i, object) {
      $(object).css('stroke-width', strokeWidthVal+"px");
      if(i % 275 === 0) {
        strokeWidthVal += 1;
      }
      if(i % 3 === 0) {
        $(object).addClass('data-slide-blue');
      }
    })
  }
// Run on load
  addDashesToSpiral();
  addDashesToSpiralMobile();

  // function spiralClick(index) {
    $(".data-slide").click(function(event) {
      event.preventDefault();
      var index = $(this).data('dot')
      addDashesToSpiral();

      $('.active-circle').removeClass('active-circle');
      // $(this).children('circle').addClass('active-circle');

      $(".data-slide[data-dot='"+index+"'] > circle").addClass('active-circle')

      $('.active-group').removeClass('active-group');
      $('#data-slide-'+index+1+'-pink').addClass('active-group');

      $('.data-slide-pink').removeClass('data-slide-pink');
      $("#spiral line").each(function (i, object) {
        if (!$(object).hasClass("data-slide-pink") && $(object).parent().data("slide") <= index) {
          setTimeout(function() {
            $(object).removeClass('data-slide-blue');
            $(object).addClass('data-slide-pink');
          }, i);
        } else if ($(object).hasClass("data-slide-pink") && $(object).parent().data("slide") > index){
          console.log('else if')
          $(object).removeClass('data-slide-pink');           
        }
      })

      if(!$("#data-slide-"+index).hasClass("is-active")) {
        $("#timeline-slider").foundation('changeSlide', true, $("#data-slide-"+index));
      }
    })
  // }

  $(".data-slide-mobile").click(function(event) {
    event.preventDefault();
    var index = $(this).data('dot')
    addDashesToSpiralMobile();

    $('.active-circle-mobile').removeClass('active-circle-mobile');
    $('.data-slide-mobile[data-dot="' + index + '"] > circle').addClass('active-circle-mobile');

    $('.active-group').removeClass('active-group');
    $('#data-slide-'+index+1+'-pink').addClass('active-group');

    $('.data-slide-pink').removeClass('data-slide-pink');
// 


    $("#spiral-mobile line").each(function (i, object) {
      if (!$(object).hasClass("data-slide-pink") && $(object).parent().data("slide") <= index) {
        setTimeout(function() {
          $(object).removeClass('data-slide-blue');
          $(object).addClass('data-slide-pink');
        }, i);
      }
      else if ($(object).hasClass("data-slide-pink") && $(object).parent().data("slide") > index){

        $(object).removeClass('data-slide-pink');

      }
    })
    if(!$("#data-slide-"+index).hasClass("is-active")) {
      $("#timeline-slider").foundation('changeSlide', true, $("#data-slide-"+index));
    }
  })


  // function triggerClick() {
  //   for (var i = 0; i < 27; i++) {
  //     if($("#data-slide-"+i).hasClass("is-active")) {
  //       $(".data-slide-"+i).trigger("click");
  //     }
  //   }
  // }

  $(".orbit-previous").on('click touchstart',  function() {
    var index = $('.active-circle').parent().data('dot') - 1
    if (index < 0) {
      index = 24
    }
    $("[data-dot="+ index +"]").click()
  })

  $(".orbit-next").on('click touchstart',  function() {
    var index = $('.active-circle').parent().data('dot') + 1
    if (index > 24) {
      index = 0
    }
    $("[data-dot="+ index +"]").click()
  })

  // for (var i = 0; i < 27; i++) {
  //   spiralClick(i);
  //   spiralClickMobile(i);
  //   triggerClick();
  // }
});
