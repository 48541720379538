$(function(){
  
// Directory page accordion
  $('.directory__title--accordion').click(function(){
    $(this).find('.directory-cta__icon-plus').toggle();
    $(this).find('.directory-cta__icon-minus').toggle();
    $(this).next().slideToggle();
  }) 
  
  $('.directory__title--accordian-startup').click(function() {
    $(this).find('.directory-cta__icon-plus').toggle();
    $(this).find('.directory-cta__icon-minus').toggle();
    
    $(this).next().slideToggle();
    $(this).next().next().slideToggle();
  })

// Slack header form submit 
//  $('#nav-slack').on('submit', function(e){
//    e.preventDefault()
//    console.log('submit')
//    var emailData = $(this).serialize();
//    $.ajax({
//      type : 'POST', // HTTP method, always use POST for our form
//      url : '/', 
//      data : emailData, // serialized form data
//      dataType : 'json', // the type of data we expect back from the server
//      success: function(data){
//        $('.slack-submit').attr("disabled", true);
//        $('.slack-success').css('display', 'block');
//        $('.slack-input').css('display', 'none');
//      },
//      error: function(response) {
//        console.log(response)
//      }
//    });
//  })

// Newsletter footer form submit 
  $('#news-sub').on('submit', function(e){
    e.preventDefault()
    console.log('submit')
    var emailData = $(this).serialize();
    $.ajax({
      type : 'POST', // HTTP method, always use POST for our form
      url : '/', 
      data : emailData, // serialized form data
      dataType : 'json', // the type of data we expect back from the server
      success: function(data){
        $('.sub-submit').attr("disabled", true);
        $('.news-sub').css('display', 'block');
        $('.news-input').css('display', 'none');
      },
      error: function(response) {
        console.log(response)
      }
    });
  })

// Newsletter footer form submit 
  $('.sub-about').on('submit', function(e){
    e.preventDefault()
    console.log('submit')
    var emailData = $(this).serialize();
    $.ajax({
      type : 'POST', // HTTP method, always use POST for our form
      url : '/', 
      data : emailData, // serialized form data
      dataType : 'json', // the type of data we expect back from the server
      success: function(data){
        $('.about-sub').css('display', 'block');
        $('.about-sub-input').css('display', 'none');
      },
      error: function(response) {
        console.log(response)
      }
    });
  })

// Newsletter footer form submit 
  $('.slack-sub-about').on('submit', function(e){
    e.preventDefault()
    console.log('submit')
    var emailData = $(this).serialize();
    $.ajax({
      type : 'POST', // HTTP method, always use POST for our form
      url : '/', 
      data : emailData, // serialized form data
      dataType : 'json', // the type of data we expect back from the server
      success: function(data){
        $('.about-slack').css('display', 'block');
        $('.about-slack-input').css('display', 'none');
      },
      error: function(response) {
        console.log(response)
      }
    });
  })
  
  //Javascript for the textarea counter
  var text_max = 1000;
  $('#textarea_feedback').html(text_max + ' characters remaining');
  $('.textarea').keyup(function() {
    var text_length = $('.textarea').val().length;
    var text_remaining = text_max - text_length;
    $('#textarea_feedback').html(text_remaining + ' characters remaining');
  });
  
  $('#textarea_feedback2').html(text_max + ' characters remaining');
  $('.textarea2').keyup(function() {
    var text_length = $('.textarea2').val().length;
    var text_remaining = text_max - text_length;
    $('#textarea_feedback2').html(text_remaining + ' characters remaining');
  });
    
  $('.alphaList').slideUp(); // hide list on default
  var myIndex = $('.alphaList').makeAlphaIndex({
      activateFirstIndex: true,
      showItemsCount: false,
    }),
    $indexBar = myIndex.alphaIndexBar.addClass('row').addClass('directory').addClass('menu').css('display', 'none').addClass('hideMenu');
//    myIndex.alphaIndexToggle('a');  // toggle all
  
})
//custom uploade button - need to make it more efficient
function uploadFileEvent(target) {
  document.getElementById('uploadedFileEvent').innerHTML = target.files[0].name;
}

function uploadFileArticle(target) {
  document.getElementById('uploadedFileArticle').innerHTML = target.files[0].name;
}

