$(function() {
  if (top.location.pathname === '/events' || top.location.pathname === '/events.html')
  {
    function getFirstDay(month, year) {
      var dateObject =  new Date();

      dateObject.setFullYear(year);
      dateObject.setMonth(month);
      dateObject.setDate(1);

      return dateObject.getDay();
    }

    var weekdays       = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var months         = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var monthTotalDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var todaysDate        = new Date();
    var todaysDay         = todaysDate.getDate();
    var todaysMonth       = todaysDate.getMonth();
    var todaysYear        = todaysDate.getFullYear();

    var visibleDay   = todaysDay;
    var visibleMonth = todaysMonth;
    var visibleYear  = todaysYear;

    $(".calendar-nav__button--next").click(function() {
      if (visibleMonth === 11) {
        visibleYear  += 1;
        visibleMonth = 0;
      }
      else {
        visibleMonth += 1;
      }
      updateCalendar(visibleMonth, visibleYear);
    });


    $(".calendar-nav__button--prev").click(function() {
      if (visibleMonth === 0) {
        visibleYear -= 1;
        visibleMonth = 11;
      }
      else {
        visibleMonth -= 1;
      }
      updateCalendar(visibleMonth, visibleYear);
    });


    function updateCalendar(month, year) {
      $( "#event-previews-wrapper" ).empty();

      checkLeapYear(year);
      var firstDay = getFirstDay(month, year);
      var queryString = months[month].toLowerCase()+"-"+year;

      $('.calendar-nav__month').text(months[month] + " " + year);

      for (var i = 0; i < 35; i++) {
        var idIndex = '#date'+ i;
        $(idIndex).text("");
        if ($(idIndex).hasClass("current-date")) {
          $(idIndex).removeClass("current-date");
        }
      }

      for (var i = 1; i < (monthTotalDays[month]) + 1; i++) {
        if (i === todaysDay && month === todaysMonth) {
          $('#date'+ (i + firstDay -1)).addClass("current-date");
        }
        $('#date'+ (i + firstDay -1)).text(i);
      }

      updateURL(queryString);

      $.get(document.location.href, function(data) {
        $( "#event-previews-wrapper" ).append( data ).hide().fadeIn(300);
      })

      .done(function() {
        for (var i = 1; i < (monthTotalDays[month]) + 1; i++) {
          placeEventTagsOnCalendar(month, i, year, (i + firstDay -1));
        }
        smoothScrolling();
      })
    }


    function checkLeapYear(year) {
      if (year % 4 === 0 && year % 100 != 0 || year % 400 === 0) {
        monthTotalDays[1] = 29;
      }
    }

    function updateURL(month) {
      if (history.pushState) {
          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?month=' + month;
          window.history.pushState({path:newurl},'',newurl);
      }
    }

    function placeEventTagsOnCalendar(month, day, year, index) {
      var id = months[month] + day + year;
      $('.sub-header--events').each(function(i, obj) {
        if ($(obj).is("#" + id)) {
          $('#date' + index).wrapInner( "<a href=#" + id + " class='calendar-main__date-event-link'></a>" );
        }
      });
    }


    function smoothScrolling() {
      // Add smooth scrolling to all links
      $("a").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();

          // Store hash
          var hash = this.hash;

          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          $('html, body').animate({
            scrollTop: $(hash).offset().top
          }, 600, function(){

            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          });
        } // End if
      });
    }

    $("#calendar-toggle").click(function() {
      $(".calendar-main").toggle();
    })


    updateCalendar(todaysMonth, todaysYear);
  }
});
